import { Ax } from '@/utils';
import formsSetVariantAttrMixin from './forms-set-variant-attr-mixin';
import BpI18nHandlerMixin from './bp-i18n-handler-mixin';
import Decimal from 'decimal.js';

//  ***************************************** //
//      Миксин для форм с расшифровками       //
//  ***************************************** //
export default {
    mixins: [formsSetVariantAttrMixin, BpI18nHandlerMixin],
    data() {
        return {
            goodsData: [],
            initialGoodsData: {},
            unitsList: [],
            templateTableOverlay: false,
            ensTruDict: [],
            ensTruName: [],
            unitsBase: [],
            descIsLoading: false,
            parNameLoading: false,
            ensTruNamePrt: [],
            currCategory: null,
            dataChanged: false,
            allFiles: [],
            timeout: null,
            currUnits: [],
            progress: 0,
            dict: [],
            deletingCat: false,
            goodsAreSaving: false,
            idxOfInfl: 0,
            isReportUploading: false,
            variantAttribute: false,
            selectAll: false,
            selectAllGoods: false,
            categoryMode: true,
            newBudgetDatas: [],
            areDoublesFound: false,
            guListLen: 0,
            formsList: ['133', '154', '155', '156', '157', '158', '163', '165', '212', '213', '311', 
            '321', '322', '331', '332', '338', '352', '411', '412', '417', '418', '419', 
            '421', '422', '423', '511', '513', '514', '612', '711', '712', '714', '812', '813', '814', 
            '815', '01-322', '02-322', '01-339', '02-339', '02-169'],
            formName: null, 
            spfName: null,
            includedTypes: [],
            isParentGuExist: false,
            isGuMode: true,
            headGuMinlevels: ['1', '3', '10'],
            paidGkkpForms: ['03-149', '04-151', '02-159', '01-169', '418'],
            mainGuCheckingResult: {},
            isGkkpMode: null,
        }
    },

    filters: {
        pointToComma: function (value) {
            if (!value) return ''
            return value.toLocaleString('ru-RU', { maximumFractionDigits: 6 })
        }
    },

    beforeRouteUpdate(to, from, next) {
        this.$refs.budgetHeader.urlChanged(to);
        next()
    },

    methods: {
        addItem(nameRu = null, nameKk = null) {
            if (this.isNotSavedCatExist) return;
            const item = { id: -1 };
            this.itemUpdate(item, nameRu, nameKk);
            this.budgetForm.push(item);
            this.goodsData.splice(0);
        }, // добавление новой строки в таблицу категорий

        itemUpdate(item) {
            // Заменить в форме
        },

        uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0;
                const v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }, // генерация уникального id

        setCurrCatName(cat, id) {
            this.currCategory = cat;
            this.categoryMode = false;
            this.loadGoodsData(cat);
        }, // установка имени текущей категории для модалки с расшифровками

        async loadGoodsData(cat) {
            this.templateTableOverlay = true;
            if (!cat.code) {this.templateTableOverlay = false; return;}
            this.initialGoodsData = {};
            const newHeader = {...this.header, cat_code: cat.code}
            try {
                const response = await fetch('/api-py/get-budget-request-form-goods/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(newHeader)
                });
                if (response.status === 200) {
                    const values = await response.json();
                    await this.ensTruDataAddingToGoods(values);
                    process.nextTick(() => {
                        values.forEach(val => {
                            this.$set(val, 'isNew', false);
                            this.$set(val, 'rowToSave', false);
                            this.$set(val, 'amountPassVal', true);
                            this.$set(val, 'pricePassVal', true);
                            this.$set(val, 'currGoodDesc', this.ensTruName.filter(ens => ens.code === val.enstru_code)[0]);
                            this.$set(val, 'ensTruName', this.ensTruName.filter(itm => itm['name_'+this.lang] === val.name));
                            this.$set(val, 'itemToDelete', false);
                            this.$set(val, 'isHasDouble', false);

                            this.setTotals(val)
                            this.setColsForForm01_152(val);
                            // добавлене списка единиц изменрения
                            const uom = [];
                            this.unitsList.filter(item => item.enstru_code === val.enstru_code)
                            .forEach(u => {
                                const unitName = this.getUnitName(u.unit_code);
                                uom.push({name: unitName, code: u.unit_code});
                            })
                            if (uom.length) {
                                this.currUnit = uom.filter(item => item.code === val.unit_code)[0];
                            } else this.currUnit = [];
                            this.$set(val, 'uom', uom);
                            this.initialGoodsData[val.id] = { ...val, total: val.total };
                        });
                        this.goodsData = values.sort((a, b) => a.id - b.id);
                        this.doublesValidationDecodes();
                    });
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadGoodsData()`, error.toString());
            }
            this.templateTableOverlay = false;
            this.goodsAreSaving = false;
        }, // загрузка списка расшифровок

        setColsForForm01_152(val) {

        }, // используется в компоненте From01_152.vue

        async ensTruDataAddingToGoods(values) {
            const codeList = [];
            const namesList = [];
            values.forEach(val => {
                this.prepareData(val);
                codeList.push(val.enstru_code);
            });
            if (codeList.length) {
                const unicCodesList = [...new Set(codeList)];
                await this.loadUnitsList(unicCodesList)
                await this.loadEnstruDict(unicCodesList);
            };
            values.forEach(val => {
                let name = '';
                let shrDescr = '';
                let esnActive = true;
                const curEns = this.ensTruDict.find(item => item.code === val.enstru_code);
                if (curEns) {
                    name = curEns['name_' + this.lang];
                    shrDescr = curEns['detail_' + this.lang] + ' (' + curEns.code + ')';
                    esnActive = curEns.active
                };
                this.$set(val, 'name', name);
                this.$set(val, 'shrDescr', shrDescr);
                this.$set(val, 'esnActive', esnActive);
                namesList.push(name);
            });
            if (namesList.length) {
                const unicNamesList = [...new Set(namesList)];
                await this.getEnstruByNamesList(unicNamesList);
            };
        },

        setTotals(val) {
            const roundedSum = Math.round(val.amount * val.price * 100) / 100;
            const total = roundedSum / 1000;
            this.$set(val, 'total', Math.round(total * 1000) / 1000);
        },  //при необходимости изменить расчет тотала в родительском компоненте

        prepareData(val) {
            //при необходимости заменить в родительском компоненте
        },

        async loadCurUnits(code) {
            try {
                const response = await fetch(`/api-py/get-enstru-unit-code/${code}`);
                if (response.status === 200) {
                    const items = await response.json();
                    this.currUnits = items;
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} unitsDict`, error.toString());
            }
        }, // получение кодов единиц измерения при добовлении товара

        async loadUnitsList(list) {
            try {
                const response = await fetch(`/api-py/get-enstru-unit-list/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(list)
                });
                if (response.status === 200) {
                    const items = await response.json();
                    this.unitsList = items;
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} unitsDict`, error.toString());
            }
        }, // получение кодов единиц измерения по сохраненным товарам

        async loadEnstruDict(list) {
            try {
                const response = await fetch('/api-py/get-enstru-by-code-list/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(list)
                });
                if (response.status === 200) {
                    const values = await response.json();
                    values.forEach(item => {
                        this.$set(item, 'name', item['name_'+this.lang]);
                    });
                    this.ensTruDict = values.sort((a, b) => a.name > b.name ? 1 : -1);
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadEnstruDict`, error.toString());
            }
            return true;
        }, // загруска справочника enstru

        async getEnstruByNamesList(list) {
            let url = `/api-py/get-enstru-by-listofnames-ru/`;
            if (this.lang === 'kz') {
                url = `/api-py/get-enstru-by-listofnames-kz/`;
            };
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify({names: list, includedTypes: this.includedTypes})}
                );
                if (response.status === 200) {
                    const values = await response.json();
                    values.forEach(item => {
                        this.$set(item, 'detail', item['detail_'+this.lang]  + ' (' + item.code + ')');
                    });
                    this.ensTruName = values.sort((a, b) => a.detail > b.detail ? 1 : -1);
                };
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} getEnstruByNamesList`, error.toString());
            }
        },

        getUnitName(u) {
            if (!u) return;
            const currUnit = this.unitsBase.filter(unit => {
                let formatedUnitCode = u;
                if (formatedUnitCode.length > 3 && formatedUnitCode.slice(0, 1) === "0") {
                    formatedUnitCode = formatedUnitCode.slice(1)
                }
                return unit.code === formatedUnitCode;
            });
            let unitName = '';
            if (currUnit.length) {
                unitName = currUnit[0][`name_${this.lng_kz}`];
            }
            return unitName;
        },

        async getEnstruByName(name) {
            this.descIsLoading = true;
            let url = `/api-py/get-enstru-active-by-name-ru/`;
            if (this.lang === 'kz') {
                url = `/api-py/get-enstru-active-by-name-kz/`;
            };
            const data = {
                name: name, 
                includedTypes: this.includedTypes,
                formCode: this.header.form
            }
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(data)}
                );
                if (response.status === 200) {
                    const values = await response.json();
                    values.forEach(item => {
                        this.$set(item, 'detail', item['detail_'+this.lang]  + ' (' + item.code + ')');
                    });
                    this.ensTruName = values.sort((a, b) => a.detail > b.detail ? 1 : -1);
                };
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} getEnstruByName`, error.toString());
            }
            this.descIsLoading = false;
        },

        openFilterByRef(refName) {
            this.$refs.budgetHeader.openFilterByRef(refName, this.header.mode);
        },

        async getEnstruByNamePart(part) {
            this.parNameLoading = true;
            let url = `/api-py/get-enstru-active-by-name-ru-part/`;
            if (this.lang === 'kz') {
                url = `/api-py/get-enstru-active-by-name-kz-part/`;
            };
            const data = {
                textPart: part, 
                includedTypes: this.includedTypes,
                formCode: this.header.form
            }
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(data)}
                );
                if (response.status === 200) {
                    const values = await response.json();
                    if (!values) return;
                    values.forEach(item => {
                        let shrtName = item['name_'+this.lang];
                        if (shrtName && shrtName.length > 20) shrtName = shrtName.slice(0, 20) + '...';
                        this.$set(item, 'shrtName', shrtName);
                        this.$set(item, 'nameForList', `${item['name_'+this.lang]} (${item.code})`);
                        this.$set(item, 'name', item['name_'+this.lang]);
                    });
                    this.ensTruNamePrt = values.sort((a, b) => a.name > b.name ? 1 : -1);

                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} getEnstruByNamePart`, error.toString());
            }
            this.parNameLoading = false;
        },

        setIsGuMode(header) {
            this.isGuMode = header ? header.mode === 'gu' : true;
        },

        async changeHeader(data) {
            try {
                this.openDisabled = true;
                const curHeader = data;
                this.setIsGuMode(curHeader);
                this.allFiles.splice(0);
                if ((curHeader !== null)
                    && (curHeader.dataType !== null)
                    && (curHeader.gr !== null)
                    && (curHeader.gu !== null)
                    && (curHeader.prg !== null)) {
                    this.header = {
                        form: curHeader.form.code,
                        year: curHeader.yearProp,
                        cur_year: curHeader.year,
                        data_type: curHeader.dataType.code,
                        gu: curHeader.gu.code,
                        id_region: curHeader.gu.id_region,
                        gr: curHeader.gr.gr,
                        abp: curHeader.abp.abp,
                        prg: curHeader.prg.prg,
                        ppr: curHeader.ppr,
                        spf: curHeader.spf.spf,
                        region_code: curHeader.region,
                        variant: curHeader.budgetVersion.variant_uuid,
                        variant_date_time: curHeader.budgetVersion.date_start,
                        variant_end_date: curHeader.budgetVersion.end_date,
                        user_name: this.$store.state.user.sub,
                        mode: curHeader.mode,
                        gkkp: curHeader.gkkp,
                        first_date_of_year_gu: curHeader.dataType.firstDateOfYear
                    };
                    
                    this.formName = curHeader.form;
                    this.spfName = curHeader.spf;
                    let filteredGuList = curHeader.guList;
                    this.isGkkpMode = this.header.mode && this.header.mode === 'gkkp';
                    if (curHeader.region.slice(-4) !== '0101') {
                        filteredGuList = curHeader.guList.filter(itm => itm.id_region === curHeader.region)
                    }
                    this.guListLen = filteredGuList ? filteredGuList.length : 0;
                    if (curHeader.gu.budget_type === '02') {
                        this.header.id_region = curHeader.region;
                    }
                    this.dataTypeFilter = curHeader.dataType;
                    this.formFilter = this.form.code + ' - ' + this.form.name_ru;

                    // если атрибут поля attribute в budget_variants имеет значение true - редактирование формы возможно,
                    // если значение false/null - редактирование запрещено

                    this.setVariantAttribute(curHeader.budgetVersion.attribute, this.header, '004.002.004')
                    
                    // console.log('НЕ ЗАБУДЬ УДАЛИТЬ', this.$store.state)
                    // setTimeout(() => {this.variantAttribute = true}, 1000)

                    this.variantName = curHeader.budgetVersion;
                    this.setDataForBreadCrumbs(this.header, curHeader);
                    this.getIfParentGuExist();
                    this.getMainGuData();
                    this.progress = 30;
                    await this.unitsDict();
                    this.progress = 60;
                    await this.loadDict();
                    this.progress = 90;
                    await Promise.all(
                        [this.loadCategoryData(),
                        this.loadCategoryDataPaid()]
                    );
                    this.progress = 100;
                } 
            } catch  (error) {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('params_updt'));
            } finally {
                this.openDisabled = false;
            }
        }, // обновление параметров

        setDataForBreadCrumbs(header, curHeader) {
            this.$emit('setDataForBreadCrumbs', header, curHeader)
        },

        async unitsDict() {
            try {
                const response = await fetch('/api/stat-dict/mkei');
                if (response.status === 200) {
                    const items = await response.json();
                    this.unitsBase = items.filter(item => item.code.length > 2);
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} unitsDict`, error.toString());
            }
        }, // справочник единиц измерения
        
        inputFixed(item, field, value, digit, defaultVal = 0) {
            // nextTick добавлен тк не без него не работает реактивность при событии keyPress.enter если предыдущее значение = 0 
            this.$nextTick(() => {
                const newVal = parseFloat(value) ? parseFloat(parseFloat(value).toFixed(digit)) : defaultVal;
                this.$set(item, field, newVal);
            })
        }, // форматирует введенное значение до digit цифр после запятой

        checkNumber(e, min, max = false) {
            let newVal = parseInt(e.target.value);
            if (!Number(e.target.value)) {
                newVal = 0;
            }
            if (newVal < min) {
                e.target.value = min;
                return;
            };
            if (max && max !== 365 && newVal > max) {
                e.target.value = max;
            };
            if (max && max === 365 && newVal > max + 1) {
                e.target.value = this.yearValidation(newVal);
                return;
            };
        }, // проверка входит ли вводимое число в заданные рамки

        yearValidation(value) {
            if (value === null || value === '' || value === '.' || !Number(value) || value < 1) {
                return;
            };
            let newVal = Number(value);
            if (this.header.year) {
                const daysInYear = this.getNumberOfDaysInYear(this.header.year);
                newVal = daysInYear < newVal ? daysInYear : newVal;
            }
            return newVal;
        }, // проверка кол-ва дней. Должно быть <= дней в году

        getNumberOfDaysInYear(year) {
            const date = new Date(year, 11, 31); // December 31st of the given year
            return date.getUTCFullYear() % 4 === 0 && (date.getUTCFullYear() % 100 !== 0 || date.getUTCFullYear() % 400 === 0) ? 366 : 365;
        }, // возвращает кол-во дней для переданного года.

        ifAllFieldsFilled() {
            const prevNewItemIdx = this.goodsData.findIndex(item => item.isNew === true);
            if (prevNewItemIdx !== -1) {
                const prevNewItem = this.goodsData[prevNewItemIdx];
                if (!prevNewItem.enstru_code) {
                    this.makeToast('warning', this.getCommonText('attention'), this.getCommonText('not_selected'));
                    return false;
                };
            }
            if (this.goodsData.findIndex(item => item.amountPassVal === false) !== -1) {
                this.makeToast('warning', this.getCommonText('attention'), this.getCommonText('amnt_more_zero'));
                return false;
            }
            if (this.goodsData.findIndex(item => item.pricePassVal === false) !== -1) {
                this.makeToast('warning', this.getCommonText('attention'), this.getCommonText('cost_more_zero'));
                return false;
            }
            if (prevNewItemIdx !== -1) this.$set(this.goodsData[prevNewItemIdx], 'isNew', false);
            return true
        }, // проверка заполненности полей у расшифровках


        resetModal(bvModalEvent) {
            if (this.dataChanged && bvModalEvent) {
                bvModalEvent.preventDefault();
                this.onGoodsModalClose();
            } else {
                this.categoryMode = true;
                this.goodsData.splice(0); 
            }
        }, // отчистка 'goods-modal' при закрытии без сохранения

        onGoodsModalClose() {
            this.$bvModal.msgBoxConfirm(
                `${this.getCommonText('unsaved_changes')}!`,
                {
                    title: `${this.getCommonText('unsaved_changes')}!`,
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('continue'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.categoryMode = true;
                        this.dataChanged = false;
                        this.goodsData.splice(0);

                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.getErrText('modal_close'), error.toString());
                });
        },

        fillFilesList(item) {
            this.allFiles.push({...item, row_id: null});
        }, // получение всех файлов из дочернего компонента

        restetFilesList() {
            this.allFiles.splice(0);
        }, //добавил т.к. при перерисовке модуля загрузки файлов появляются дубли

        deleteLinkToFile(fileCode = null) {
            if (!fileCode) {
                this.budgetForm.forEach(item => {
                    this.$set(item, 'files', []);
                });
                return;
            };
            this.budgetForm.forEach(item => {
                const idx = item.files ? item.files.findIndex(file => file === fileCode) : -1;
                if (idx !== -1) {
                    item.files.splice(idx, 1);
                };
            });
        }, // удаление кода файла из списка категории

        triggDownloadFile(filename) {
            this.$refs.filesUpdown.downloadFile(filename);
        }, // инициация скачивания файла

        triggDownloadAllFiles(files) {
            this.$refs.filesUpdown.downloadAllFile(files);
        }, // инициация скачивания файлов

        async updateDatas(onlyCat=false, clearAllFiles = true) {
            const stack = new Error().stack;

            if (clearAllFiles) this.allFiles.splice(0);
            await this.loadCategoryData();
            if (!onlyCat) {
                this.loadGoodsData(this.currCategory);
            }
        },

        deleteItem(msg, row = false, index = false) {
            if (!this.variantAttribute || this.isLoad
                || (!row && this.budgetForm.findIndex(itm => itm.itemToDelete) === -1)) return;
            this.currCategory = row.good_type;

            this.$bvModal.msgBoxConfirm(
                this.getCommonText('del_with_decodes', {msg: msg}),
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    if (value) {
                        this.deletingCat = true;
                        if (row) {
                            if (this.currCategory) {
                                await this.loadGoodsData(this.currCategory);
                            }

                            process.nextTick(async () => {
                                if (row.id >= 0) {
                                    const isHasGoods = this.goodsData && this.goodsData.length > 0
                                    await this.delete(row, !isHasGoods);
                                    this.deleteGood(this.goodsData, true, true);
                                } else {
                                    this.budgetForm.splice(index, 1);
                                }
                                this.disableDictItms();
                                this.afterDeleted();
                            });
                        } else {
                            const existingRowsToDel = this.budgetForm.filter(item => item.id >= 0 && item.itemToDelete);
                            this.budgetForm = this.budgetForm.filter(item => !item.itemToDelete);
                            if (!existingRowsToDel.length) {
                                this.selectAll = false;
                            } else {
                                if (['01-141', '01-142', '01-153', '03-141', '02-141'].includes(this.form.code)) { // очистка таблицы -decode, где есть
                                    const existingGoodsToDel = [];
                                    existingRowsToDel.forEach(row => existingGoodsToDel.push(row.category_id));
                                    await this.deleteSeveralGoods(existingGoodsToDel, 0, true);
                                }

                                await this.deleteSeveralCats(existingRowsToDel);
                            }
                            this.afterDeleted();
                        }
                    }
                })
                .catch(error => {
                    this.makeToast('danger', this.getCommonText('on_del'), error.toString());
                    this.deletingCat = false;
                })
                .finally(() => {
                    this.deletingCat = false;
                })
        },

        afterDeleted() {
            // при необходимости подменить в родительском компоненте
        },
        async delete() {
            //  на случай, если нужно дополнительно удалить что-то еще. Напр Form01_142
        },

        disableDictItms() {
            // при необходимости подменить в родительском компоненте. Напр Form03_149
        },
        // пересчет тотала при удалении нескольких записей
        calcGoodsTotalBeforeDel() {
            // при необходимости заменить в родителе
            return this.budgetForm.length ? this.budgetForm.reduce((acc, sum) => acc + sum[this.getTotalFieldName()], 0) : 0;
        },
        async deleteSeveralGoods(items, totalDeleting, catDeleting = false) {
            const totalCats = this.calcGoodsTotalBeforeDel();
            let totalVal = totalCats
            if (this.form.code !== '01-153') {
                totalVal = totalVal - totalDeleting;
            }
            const dataToDel = {
                items,
                header: { ...this.header, value: Math.ceil(totalVal) },
                form: this.getFormCode(),
                catDeleting,
            }
            try {
                const response = await fetch('/api-py/delete-several-goods/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataToDel)
                });
                const result = await response.json();
                if (!catDeleting && (response.status === 200) && (result.result === 'success')) {
                    if (!this.goodsData.filter(itm => itm.id > 0).length) {
                        this.resetModal();
                    }
                    this.updateDatas(true)
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('deleted'));
                    this.deletingAgreementTotalResultHandler(result);
                }
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
            } finally {
                this.disableDictItms();
                this.deletingCat = false;
                this.selectAll = false;
            }
        },

        // пересчет тотала при удалении нескольких записей
        calcTotalBeforeDel() {
            // при необходимости заменить в родителе
            return this.budgetForm.length ? this.budgetForm.reduce((acc, sum) => acc + sum.total, 0) : 0;
        },
        // удаление нескольких категорий
        async deleteSeveralCats(items) {
            const totalVal = this.calcTotalBeforeDel();
            const dataToDel = {
                items,
                header: { ...this.header, value: Math.ceil(totalVal) },
                form: this.form.code,
                catIdField: this.getCodeFieldName()
            }
            try {
                const response = await fetch('/api-py/delete-several-cats/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataToDel)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    this.dataChanged = false;
                    this.updateDatas(true)
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('deleted'));
                    this.deletingAgreementTotalResultHandler(result);
                }
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
            } finally {
                this.disableDictItms();
                this.deletingCat = false;
                this.selectAll = false;
            }
        },

        async deleteGood(item, showMsg = true, isCatDeleting = false) {
            if (!item.length) return;
            this.updateTotalBeforeSave(isCatDeleting, 'totalSaved');
            const dataToDel = {
                item,
                header: this.header,
                form: this.getFormCode()
            }
            try {
                const date = new Date()
                const response = await fetch('/api-py/delete-list-budget-request-form/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataToDel)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (!this.goodsData.filter(itm => itm.id > 0).length) {
                        this.resetModal();
                    }
                    await this.updateDatas(true);
                    if (showMsg) this.makeToast('success', this.getErrText('msg'), this.getErrText('one_deleted'));
                    this.deletingAgreementTotalResultHandler(result);
                    // this.setDataChanged(false);
                    this.doublesValidationDecodes();
                }
                return response.status;
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_del'));
                return response.status;
            }
        }, // удаление данных

        updtHeader(total = this.total) {
            this.$set(this.header, 'value', total);
        }, // добавление тотала в хедер перед схранением

        async saveTotal() {
            this.$set(this.header, 'value', this.total);
            try {
                const url = this.header.mode === 'gkkp' ? '/api-py/save-budget-request-total-gkkp/' : '/api-py/save-budget-request-total/';
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.header)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                }
            } catch {
                this.makeToast('danger', this.getErrText('warning'), this.getErrText('on_save_total'));
            }
        }, // сохранение итога


        onDataChanged(item) {
            this.$set(item, "rowToSave", true)
            this.dataChanged = true;

            if (!item.amount || item.amount <= 0) {
                this.$set(item, "amountPassVal", false);
            } else this.$set(item, "amountPassVal", true);
            if (!item.price || item.price <= 0) {
                this.$set(item, "pricePassVal", false);
            } else this.$set(item, "pricePassVal", true);
        }, // переключения тригера при изменении данных товаров

        searchName(text) {
            if (text.length === 0) {
                this.ensTruNamePrt.splice(0);
                clearTimeout(this.timeout);
                return;
            }
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => this.getEnstruByNamePart(text), 1500)
        },

        showFileUploadModal() {
            this.$refs.filesUpdown.showModalFileUpload();
        }, // вызов окна загрузки файлов

        prepareForSave(event) {
            event.preventDefault();
            if (this.isDoubleDecodeExist()) {
                this.makeToast('warning', this.getErrText('not_saved'), this.getErrText('not_valid'));
                return;
            }
            if (!this.ifAllFieldsFilled()) return;
            this.resetSavingAtemptsLimit();
            this.goodsAreSaving = true;
            this.updateTotalBeforeSave();
            const values = [];
            for (const row of this.goodsData) {
                if (!row.rowToSave) continue;
                const item = Object.assign({}, this.header);
                this.fillDataForSave(item, row);
                this.$set(item, 'id', row.id);
                this.$set(item, 'enstru_code', row.enstru_code);
                this.$set(item, 'add_detail', row.add_detail ? row.add_detail : null);
                this.$set(item, 'unit_code', row.unit_code ? row.unit_code : null);
                this.$set(item, 'file_hash', row.file_hash ? row.file_hash : null);
                this.$set(item, 'totalToSave', this.total);
                values.push(item);
            }
            if (this.budgetForm.find(item => item.checkForDuplicates === true)) {
                this.makeToast('danger', this.getErrText('warning'), `${this.getErrText('not_valid')}. ${this.getErrText('not_saved')}!`);
                this.goodsAreSaving = false;
            }
            else {
                if (values.length && this.variantAttribute) {
                    this.save(values);
                }
                else {
                    this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
                    this.goodsAreSaving = false;
                }
            }
        },

        resetSavingAtemptsLimit() {
            if (this.hasOwnProperty('savingAtemptsLimit')) {
                this.savingAtemptsLimit = 3;
            }
            if (this.hasOwnProperty('savingTriesCounter')) {
                this.savingTriesCounter = 0;
            }
        },

        fillDataForSave(item, row) {
            // при необходимости заменить в соответствии с формой
            this.$set(item, 'good_type', this.currCategory.code);
            this.$set(item, 'amount', parseInt(row.amount));
            this.$set(item, 'price', parseFloat(row.price));
        }, // заполнение полей при подготовке к сохранению товаров

        getFormCode() {
            // при необходимости заменить в родительском компоненте. Напр. Form01_142.vue
            return this.form.code;
        },

        async save(values, onlyCats = false, showMsg = true, addHeader = true) {
            this.isLoad = true;
            const dataToSave = {
                values,
            }

            const isHasDecodeTable = ['01-141', '01-142', '01-153', '02-141', '03-141'].includes(this.form.code)
            if (addHeader) dataToSave.header = this.header;
            try {
                const service =  isHasDecodeTable ? '/api-py/save-brform-gkkp-decode/' : '/api-py/save-brform-gkkp-headerinside/';
                const url = this.header.mode === 'gkkp' ? service : '/api-py/save-brform' + this.getFormCode() + '/'
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(dataToSave)
                });
                const result = await response.json();
                if (response.status === 200) {
                    this.dataChanged = false;
                    this.goodsData.forEach(good => {
                        if (good.id < 0) this.$set(good, 'id', good.id * -1)
                    })
                    this.updateDatas(onlyCats);
                    this.selectAll = false;
                    if (showMsg) this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                } else {
                    this.goodsAreSaving = false;
                    throw this.getErrText('not_saved');
                }
            } catch (e) {
                this.goodsAreSaving = false;
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        setCustomUrlForSave() {
            // в каждой форме изменить на свой url
            return ''
        },

        prepareForSaveCats(form) {
            form.forEach(item => {
                if (item.rowToSave) {
                    this.saveCats(item);
                };
            })
        },

        async saveCats(row, saveTotals = true) {
            if (row.newCat || row.rowToSave) {
                this.updtHeader();
                const values = [{...row, ...this.header}];
                this.budgetForm.forEach(bf => {
                    const isRowShouldBeSaved = this.isCatShouldBeSaved(row, bf); 
                    if (isRowShouldBeSaved) values.push({...bf, ...this.header});
                });
                const dataToSave = {
                    values: values,
                    header: this.header,
                    saveTotals
                }
                
                try {
                    const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp-headerinside/' : '/api-py/save-brform' + this.form.code + '/'
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(dataToSave)
                    });
                    const result = await response.json();
                    if (response.status === 200) {
                        this.updateDatas(true);
                        this.deletingAgreementTotalResultHandler(result);
                } else {
                        throw this.getErrText('not_saved');
                    }
                } catch (e) {
                    this.makeToast('danger', this.getErrText('warning'), e.toString());
                } finally {
                }
            }
        }, // слхранение категорий

        isCatShouldBeSaved(row, bf) {
            return false  // при необходимости логика заменяется в компоненте формы
        },

        areThereDoubles() {
            if (this.areDoublesFound) this.makeToast('warning', this.getErrText('warning'), this.getErrText('doubles'));
            return this.areDoublesFound
        },

        async loadDict() {
            this.dict.splice(0);
            try {
                const response = await fetch('/api-py/dictionary/good_types/');
                if (response.status === 200) {
                    const items = await response.json();
                    for (const row of items) {
                        const el = {...row};
                        this.$set(el, "$isDisabled", false);
                        Object.defineProperty(el, 'name', {
                            get: () => {
                                return row[`name_${this.lng}`];
                            }
                        });
                        this.dict.push(el);
                    };
                    this.dictSortingByName(this.dict);
                    this.createDictCopy(this.dict);
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDict`, error.toString());
            }
        },

        dictSortingByName(dict) {
            dict.sort((a, b) => a[`name_${this.lng}`] > b[`name_${this.lng}`] ? 1 : -1);
        },

        async delelteAttachedFile(file_name = null, file_type = null) {
            if (!file_name || !file_type) {
                this.deleteLinkToFile();
                return;
            }
            const fileCode = this.stringToUnicode(file_type + file_name);
            try {
                const response = await fetch(`/api-py/delete-linked-file/${fileCode}/${JSON.stringify(this.header)}`);
                if (response.status === 200) {
                    this.deleteLinkToFile(fileCode);
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('msg'), this.getErrText('files', {msg: e.toString()}));
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        stringToUnicode(string) {
            let chr = '';
            if (string.length === 0) return hash;
            for (let i = 0; i < string.length; i++) {
                chr += string.charCodeAt(i);
            }
            return chr;
        },

        async onNameChanged(row, categoryFieldNames) {
            const fieldName_ru = categoryFieldNames.ru;
            const fieldName_kk = categoryFieldNames.kk;

            const isNameWasChanged = !row.newCat && (row.oldName_ru !== row[fieldName_ru] || (row.oldName_kk !== row[fieldName_kk]));
            if (row.newCat || isNameWasChanged) {
                this.$set(row, 'nameUdating', true);
                await this.loadGoodsData(row);
                
                process.nextTick(async () => {
                    if (!this.goodsData || !this.goodsData.length) {
                        this.$set(row, 'nameUdating', false);
                        return;
                    }

                    try {
                        this.goodsData.forEach(item => {
                            this.$set(item, fieldName_ru, row[fieldName_ru]);
                            this.$set(item, fieldName_kk, row[fieldName_kk]);
                        });
                        this.updtHeader();
                        const dataToSave = {
                            values: this.goodsData,
                            header: this.header
                        }
                        const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp-headerinside/' : '/api-py/save-brform' + this.form.code + '/'
                        const response = await fetch(url, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json;charset=utf-8'
                            },
                            body: JSON.stringify(dataToSave)
                        });
                        const result = await response.json();
                        if ((response.status === 200) && (result.result === 'success')) {
                            this.$set(row, 'oldName_ru', row[fieldName_ru]);
                            this.$set(row, 'oldName_kk', row[fieldName_kk]);
                            this.deletingAgreementTotalResultHandler(result);
                        }
                        this.$set(row, 'nameUdating', false);
                    } catch (e) {
                        this.makeToast('danger', this.getErrText('unable_save_name'), e.toString());
                        this.$set(row, 'nameUdating', false);
                        this.$set(row, fieldName_ru, row.oldName_ru);
                        this.$set(row, fieldName_kk, row.oldName_kk);
                    }
                })
            }
        }, // сохранение нового наименования в БД

        formatText(text, len) {
            if (!text) return;
            if (text.length > len) {
                return text.slice(0, len);
            } else {
                return text;
            }
        }, // обрезание тексат длина которого больше зданной

        onChangeName(newVal, row, fieldName) {
            this.checkForDuplicates(fieldName)
        }, // поиск дублей в наименовании категорий при ручном вводе
        
        checkForDuplicates(fieldName) {
            const existingItems = new Map();
            let doublesFound = false;
            this.budgetForm.forEach((item, idx) => {
                const trimedCurField = item[fieldName].trim().toLowerCase();
                if (existingItems.has(trimedCurField)) {
                    this.budgetForm[existingItems.get(trimedCurField)].checkForDuplicates = true;
                    item.checkForDuplicates = true;
                    doublesFound = true;
                } else {
                    item.checkForDuplicates = false;
                    existingItems.set(trimedCurField, idx)
                }
            });
            this.areDoublesFound = doublesFound;
        },
        
        isEditForbidden(row) {
            return this.areDoublesFound && !row.checkForDuplicates;
        },

        async loadInflIdx() {
            const budget = this.header.id_region.slice(-4) === '0101' ? this.header.id_region.slice(0, 2) + '0000' : this.header.id_region.slice(0, 4) + '00';
            try {
                const resp = await fetch(`/api-py/kato-code-from-budget/${budget}`)
                if (resp.status === 200) {
                    const instCode = await resp.json();
                    const response = await fetch(`api/input-forms/data/budget-filter/650470?katoCode=${instCode}&year=${this.header.year}`);
                    if (response.status === 200) {
                        const value = await response.json();
                    if (value && value.value) this.idxOfInfl = this.safeDecimal(value.value).div(100).toNumber();
                    }
                }
            } catch (error) {
                this.makeToast('danger', this.getErrText('infl_coef'), error.toString());
            }
        }, // получение индекса инфляции

        getUnicFilesArr(val) {
            if (!val.all_files) return [];
            const filesList = [];
            [...new Set(val.all_files.split(';'))].forEach(item => {
                if (item) filesList.push(item);
            });
            return filesList;
        }, // получаем масив с уникальными кодами прикрепленных файлов для категорий

        onAllFilesClick(item) {
            this.$refs.modalAllFilesManagement.showModal(item);
        },

        updateTotalBeforeSave(isCatDeleting = false, prop = 'totalAll') {
            const currTotal = isCatDeleting ? 0 : this.$refs.formTemplate.getTotal()[prop];
            if (currTotal >= 0) {
                for (const cat of this.budgetForm) {
                    if (cat[this.getCodeFieldName()] === this.currCategory.code) {
                        this.$set(cat, this.getTotalFieldName(), currTotal)
                    }
                }
            }
            this.updtHeader();
        },

        getTotalFieldName() {
            return 'total'
        }, // имя поля с тоталом, при необходимости заменить в родителе

        getCodeFieldName() {
            return 'categoryCode';
        }, // при необходимости заменить в родителе
        
        async checkSignatories(batch = false, oneGu = false) {
            this.header.code_modules = this.moduleCode;
            this.header.batch = batch;
            this.header.oneGu = oneGu;
            try {
                const response = await fetch('/api-py/check-signatories/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.header)
                });
                if (response.status === 200) {
                    const result = await response.json();
                    if (result > 0) {
                        this.showSignersModal(result)
                    }
                } else {
                    this.makeToast('danger', this.getErrText('subsc'), `${response.status} error`);
                }
            } catch (error) {
                this.makeToast('danger', this.getErrText('subsc'), error.toString());
            }
        }, // проверка наличия подписантов перед скачиванием

        showSignersModal(forms) {
            this.$bvModal.msgBoxOk(this.getCommonText('no_subsc'), {
              size: 'lg',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: this.getCommonText('close'),
              headerClass: 'p-2',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            })
            .then(value => {
            })
            .catch(err => {
            // An error occurred
            })
        },
        
        downloadRep(fileType) {
            this.isReportUploading = true;
            const newHeader = {
                ...this.header, 
                lang: this.$i18n.locale,
                code_modules: this.moduleCode,
                isGkkpPaid: false,
                output_file_type: fileType
            }
            Ax(
                {
                    url: '/api-py/budg_' + this.form.code.replace('-', '_') + '/' + JSON.stringify(newHeader),
                    method: 'POST',
                    responseType: 'blob'
                },
                async (data) => {
                    if (data.type === 'application/json') {
                        const text = await data.text();
                        const result = JSON.parse(text);
                        if ('error' in result && 'message' in result) {
                            this.makeToast('danger', 'Ошибка получения файла', `${result.error} - ${result.message}`);
                        }
                    } else {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${this.getCommonText('form')} ${this.form.code.replace('-', '_')}.${fileType}`);
                        document.body.appendChild(link);
                        link.click();
                    }
                    this.isReportUploading = false;
                },
                (error) => {
                    this.isReportUploading = false;
                    this.makeToast('danger', `${this.getErrText('bad_request')} downloadRep()`, error.toString());
                }
            );
        },

        downloadBatchReports(fileType, oneGu, type = null) {
            if (oneGu === false) {
                this.handleMainGuError();
            }
            this.isReportUploading = true;
            this.$store.commit('setIsDownloadButtonEnabled');
            this.makeToast('info', this.getCommonText('attention'), this.getCommonText('upload'));
            const newHeader = {
                ...this.header, 
                lang: this.$i18n.locale,
                oneGu: oneGu === true,
                code_modules: this.moduleCode,
                rep_type: type,
                isGkkpPaid: false,
                output_file_type: fileType
            }
            Ax(
                {
                    url: '/api-py/budg_batch_reports/' + JSON.stringify(newHeader),
                    method: 'POST',
                    responseType: 'blob'
                },
                async (data) => {
                    if (data.type === 'application/json') {
                        const text = await data.text();
                        const result = JSON.parse(text);
                        if ('error' in result && 'message' in result) {
                            this.makeToast('danger', 'Ошибка получения файла', `${result.error} - ${result.message}`);
                        }
                    } else {
                        if (data && data.size > 0) {
                            const url = window.URL.createObjectURL(new Blob([data]));
                            const link = document.createElement('a');
                            link.href = url;
                            const guOrGkkp = this.isGuMode ? this.getCommonText('gu') : this.getCommonText('gkkp');
                            const abpOrDep = type === 'edu' ? this.getCommonText('departament') : this.getCommonText('abp');
                            const fileName = this.getCommonText('butch_rep', {gu_gkkp_abp: oneGu === true ? guOrGkkp : abpOrDep})
                            link.setAttribute('download', `${fileName}.${fileType}`);
                            document.body.appendChild(link);
                            link.click();
                        } else {
                            throw new Error('Error');
                        }
                    }
                    this.$store.commit('setIsDownloadButtonEnabled');
                    this.isReportUploading = false;
                },
                (error) => {
                    this.isReportUploading = false;
                    this.makeToast('danger', `${this.getErrText('bad_request')} downloadBatchReports()`, error.toString());
                    this.$store.commit('setIsDownloadButtonEnabled');
                }
            );
        },

        downloadBatchReportsPaid(fileType, oneGu, type = null) {
            this.isReportUploading = true;
            this.$store.commit('setIsDownloadButtonEnabled');
            this.makeToast('info', this.getCommonText('attention'), this.getCommonText('upload'));
            const newHeader = {
                ...this.header, 
                lang: this.$i18n.locale,
                oneGu: oneGu === true,
                code_modules: this.moduleCode,
                rep_type: type,
                isGkkpPaid: true,
                output_file_type: fileType
            }
            Ax(
                {
                    url: '/api-py/budg_batch_reports_paid/' + JSON.stringify(newHeader),
                    method: 'POST',
                    responseType: 'blob'
                },
                async (data) => {
                    if (data.type === 'application/json') {
                        const text = await data.text();
                        const result = JSON.parse(text);
                        if ('error' in result && 'message' in result) {
                            this.makeToast('danger', 'Ошибка получения файла', `${result.error} - ${result.message}`);
                        }
                    } else {
                        if (data && data.size > 0) {
                            const url = window.URL.createObjectURL(new Blob([data]));
                            const link = document.createElement('a');
                            link.href = url;
                            const fileName = this.getCommonText('butch_rep', {gu_gkkp_abp: this.getCommonText('gkkp') + '.' +this.getCommonText('paid_services_shrt')});
                            link.setAttribute('download', `${fileName}.${fileType}`);
                            document.body.appendChild(link);
                            link.click();
                        } else {
                            throw new Error('Error');
                        }
                    }
                    this.$store.commit('setIsDownloadButtonEnabled');
                    this.isReportUploading = false;
                },
                (error) => {
                    this.isReportUploading = false;
                    this.makeToast('danger', `${this.getErrText('bad_request')} downloadBatchReportsPaid()`, error.toString());
                    this.$store.commit('setIsDownloadButtonEnabled');
                }
            );
        },

        setPassVals(item) {
            this.$set(item, "nummealsPassVal", !(!item.num_meals || item.num_meals <= 0));
            this.$set(item, "numdayPassVal", !(!item.num_day || item.num_day <= 0));
            this.$set(item, "normperPassVal", !(!item.norm_per || item.norm_per <= 0));
            this.$set(item, "pricecurPassVal", !(!item.price_cur || item.price_cur <= 0));
            this.$set(item, "priceidxedPassVal", !(!item.price_indexed || item.price_indexed <= 0));
        },

        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        calcTotalForDelete(items, calcField) {
            let sum = 0;
            for (const row of items) {
                if (row.id > 0) {
                    sum += parseFloat(row[calcField]);
                }
            }
            return Math.ceil(sum);
        },

        setIsAllDelete(val, data = this.budgetForm) {
            data.forEach(item => this.$set(item, 'itemToDelete', val));
        },

        getFirstWordInString(str) {
            if (typeof str !== 'string' || !str.length) return '';
            const spacePosition = str.indexOf(' ');
            if (spacePosition === -1) return str;
            const firsWord = str.substring(0, spacePosition);
            return firsWord;
        }, // возвращает первое слово из передоваемой строки
        
        setProgress(val) {
            this.progress = val;
        },

        setDataChanged(isChanged) {
            this.dataChanged = isChanged;
        },

        roundNumber(input, decimalPlaces) {
            const numberValue = Number(input) || 0;
            const decimalPlacesValue = Number(decimalPlaces) || 0;
            const rank = Math.pow(10, decimalPlacesValue);
            const mult = Math.round(numberValue * rank * 100) / 100;
            const roundedNumber = Math.round(mult) / rank;
            return roundedNumber;
        },
        
        setLocNames() {
            this.goodsData.forEach(item => {
                this.setGoodName(item);
            })
        },

        setGoodName(val) {
            let name = '';
            let shrDescr = '';
            const curEns = this.ensTruDict.find(item => item.code === val.enstru_code);
            if (curEns) {
                name = curEns['name_' + this.lang];
                shrDescr = curEns['detail_' + this.lang] + ' (' + curEns.code + ')';
            }
            this.$set(val, 'name', name);
            this.$set(val, 'shrDescr', shrDescr);
        },

        async getIfParentGuExist() {
            this.isParentGuExist = false;
            const curGu = this.header.gu;
            if (!this.isGuMode || !curGu) return;

            const varStartDate = this.header.variant_date_time;
            const varEndDate = this.header.variant_end_date;
            const userId = this.header.user_name;
            const formatedFirstDayOfYear = this.header.first_date_of_year_gu ? this.header.first_date_of_year_gu : null;
            try {
                const response = await fetch(`/api-py/get-if-parent-gu-exist/${curGu}/${userId}/${varStartDate}/${varEndDate}/${formatedFirstDayOfYear}`);
                if (response.status === 200) {
                    const result = await response.json();
                    this.isParentGuExist = result;
                }
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} getIfParentGuExist`, error.toString());
            }
        },
        
        onEditCatName(row) {
            const modal = this.$refs.setCatNameModal;
            if (modal) modal.showModal(row);
        },

        onAddNewCat() {
            if (this.isNotSavedCatExist) return;
            const modal = this.$refs.setCatNameModal;
            if (modal) modal.showModal();
        },

        async loadCategoryDataPaid() {
            // при необходимости заменить в родительском компоненте
        },

        createDictCopy(dict) {
            // при необходимости заменить в родительском компоненте
        },

        selfSum(sum, val) {
            return this.safeDecimal(val).add(sum).toNumber();
        },

        safeDecimal(value) {
            if (value === undefined || value === null || value === '') {
                return new Decimal(0);
            }
            return new Decimal(parseFloat(value));
        },

        deletingAgreementTotalResultHandler(result) {
            if ('agreement_deleting_result' in result) {
                const res = result.agreement_deleting_result;
                console.log('Service budget_request_total_agreement_delete has complited');
                console.log(`Result: ${res.result}`);
                if ('delete' in res) {
                    console.log(`Agreement total propably was deleted: ${res.delete}`);
                }
                if ('error' in res) {
                    console.log(`Error message: ${res.error}`);
                }
                console.log(`_______________________________`);
                console.log(``);

            }
            if ('agreement_deleting_result' in result && result.agreement_deleting_result.result === 'error') {
                const res = result.agreement_deleting_result;
                const errorDescr = 'error' in res ? res.error : '';
                this.makeToast('danger', 'budget-request-total-agreement-delete', `Ошибка ${errorDescr}`);
            }            
        },

        async getMainGuData() {
            const variant_uuid = this.header.variant;
            const abp = this.header.abp;
            if (!variant_uuid || !abp) return;
            const response = await fetch(`/api-py/get-code-gu-main/${variant_uuid}/${abp}`);
            if (response.status === 200) {
                this.mainGuCheckingResult = await response.json();
            }
        },

        handleMainGuError() {
            if (this.mainGuCheckingResult 
                && this.mainGuCheckingResult.error
                && this.mainGuCheckingResult.error.code == 1) {
                    this.makeToast('warning', this.getCommonText('attention'), this.mainGuCheckingResult.error.message[this.lang]);
            }
        },

        doublesValidationDecodes(goodsData = this.goodsData) {
            const doublesMap = {};
            this.$nextTick(() => {
                goodsData.forEach(item => {
                    const addDetailTrimmed = item.add_detail ? item.add_detail.trim().toLowerCase() : '';
                    const unitCode = item.unit_code ? item.unit_code : '';
                    const enstruCode = item.enstru_code;
                    if (!!enstruCode) {
                        const mapKey = `${enstruCode}_${addDetailTrimmed}_${unitCode}`;
                        if (doublesMap[mapKey]) {
                            doublesMap[mapKey].push(item.id);   
                        } else {
                            doublesMap[mapKey] = [item.id];
                        }
                    }
                })
                const doubleIdsList = []
                for (const key in doublesMap) {
                    const idsList = doublesMap[key];
                    const isKeyHasDoubles = idsList.length > 1;
                    if (isKeyHasDoubles) {
                        doubleIdsList.push(...idsList);
                    }
                }

                if (doubleIdsList.length > 0) {
                    goodsData.forEach(item => {
                        if (doubleIdsList.includes(item.id)) {
                            this.$set(item, 'isHasDouble', true);
                        } else {
                            this.$set(item, 'isHasDouble', false);
                        }
                    })
                } else (
                    goodsData.forEach(item => this.$set(item, 'isHasDouble', false))
                )
            })
        },

        isDoubleDecodeExist(goodsData = this.goodsData) {
            for (const item of goodsData) {
                if (item.isHasDouble) {
                    return true;
                }
            }

            return false;
        },

        onFilePreview(fileName) {
            const params = JSON.parse(JSON.stringify(this.header));
            this.$set(params, 'file_name', fileName);
            const encodedParams = encodeURIComponent(JSON.stringify(params));
            const routeData = this.$router.resolve({
                name: 'document-preview',
                query: { data: encodedParams },
            });
            window.open(routeData.href, '_blank');
        }
    },

    computed: {
        lang() {
            let lang = 'ru';
            if (this.$i18n.locale === 'kk') lang = 'kz';
            if (this.$i18n.locale === 'en') lang = 'en';
            return lang;
        },
        lng() {
            let lng = 'ru';
            if (this.$i18n.locale === 'kk') lng = 'kk';
            return lng;
        },
        lng_kz() {
            let lng = 'ru';
            if (this.$i18n.locale === 'kk') lng = 'kz';
            return lng;
        },
        moduleCode() {
            return this.formsList.includes(this.form.code) ? '004.002.005' : '004.002.004';
        },
        
        isNotSavedCatExist() {
            return this.budgetForm.findIndex(item => item.id === -1) !== -1;
        },

        codeGuMain() {
            if (this.mainGuCheckingResult && this.mainGuCheckingResult.code_gu_main) {
                return this.mainGuCheckingResult.code_gu_main;
            }
            return null;
        },

        isHeadGu() {
            if (this.codeGuMain && this.header.gu && this.codeGuMain === this.header.gu) {
                return true; 
            }
            return false;
        }
    }
}